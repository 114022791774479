import React, { useState } from "react";
import CustomLink from "./Link";

export default function SiteLink({ data }) {
  const [selected, setSelected] = useState(null);

  const onSelect = (index) => {
    if (index === selected) {
      setSelected(null);
    } else setSelected(index);
  };

  return (
    <div className="max-w-[1100px] mx-auto mt-10 mb-5 px-5">
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-y-5 ">
        {data?.map((item, index) => {
          return (
            <div key={index}>
              <p
                className={`text-assent text-base font-bold transition-all duration-500 ${
                  selected === index ? "pb-5" : ""
                }   font-primary border-t border-b uppercase py-1 hover:text-primary cursor-pointer`}
                onClick={() => onSelect(index)}
              >
                {item.label}
              </p>
              <div className="p-4">
                {item.childs?.map((child, i) => (
                  <CustomLink key={i} data={child} />
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
